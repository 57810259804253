type Props = {
  lot: Catalog.Lot;
};

export default function LotThumbnail(props: Props) {
  const image = props.lot.image;

  return (
    <div className="image">
      <div className="image-wrapper">
        {image && <img src={image.small} alt={props.lot.title} />}
      </div>
    </div>
  );
}
