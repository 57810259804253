type Props = {
  label: string;
  value: string;
  view: string;
  setView: (value: string) => void;
};

export default function ViewButton(props: Props) {
  const handleClick = () => {
    props.setView(props.value);
  };

  const classNames = ["icon", props.value];
  if (props.view == props.value) {
    classNames.push("current");
  }

  return (
    <button className={classNames.join(" ")} onClick={handleClick}>
      {props.label}
    </button>
  );
}
