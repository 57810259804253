import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import ExpertVisitForm from "./ExpertVisitForm";

type Props = {
  strings: Record<string, string>;
  url: string;
  recaptcha_key: string;
};

export default function ExpertVisit(props: Props) {
  return (
    <GoogleReCaptchaProvider reCaptchaKey={props.recaptcha_key}>
      <ExpertVisitForm {...props} />
    </GoogleReCaptchaProvider>
  );
}
