import { useState, useEffect } from "react";

type Artist = {
  id: number;
  name: string;
};

type Props = {
  artists: Artist[];
  base_url: string;
  featured_label: string;
};

export default function Artists({ artists, base_url, featured_label }: Props) {
  const firstLetters = Array.from(
    new Set(artists.map((artist) => firstLetter(artist)))
  ).sort();

  const [filter, setFilter] = useState<string>(firstLetters[0]);

  useEffect(() => {
    const readStateFromLocation = () => {
      if (typeof document !== "undefined" && document.location.hash) {
        const letter = document.location.hash.match(/(\w)/)?.[0];
        if (letter) setFilter(letter);
      } else {
        setFilter(firstLetters[0]);
      }
    };

    readStateFromLocation();
    window.onpopstate = readStateFromLocation;

    return () => {
      window.onpopstate = null; // Cleanup on unmount
    };
  }, [firstLetters]);

  function firstLetter(artist: Artist): string {
    return artist.name[0].toUpperCase();
  }

  function filterArtists(letter: string) {
    return artists
      .filter((a) => firstLetter(a) === letter)
      .sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
  }

  function artistColumns() {
    const filteredArtists = filterArtists(filter);
    let perColumn = Math.ceil(filteredArtists.length / 3);
    if (perColumn < 15) perColumn = 15;

    const columns = Array.from(
      { length: Math.ceil(filteredArtists.length / perColumn) },
      (_, i) => filteredArtists.slice(i * perColumn, i * perColumn + perColumn)
    );

    return columns;
  }

  function artistUrl(artist: Artist) {
    return `${base_url}/${artist.id}`;
  }

  function navClass(letter: string) {
    return letter === filter ? "current" : "";
  }

  function handleFilterClick(letter: string) {
    return (evt: React.MouseEvent) => {
      evt.preventDefault();
      if (window.history && window.history.pushState) {
        window.history.pushState(null, null, `#${letter}`);
      }
      setFilter(letter);
    };
  }

  function renderArtists() {
    const columns = artistColumns();
    return (
      <div className="list">
        {columns.map((column, idx) => (
          <ul className="column" key={`column-${idx}`}>
            {column.map((artist) => (
              <li key={`artist-${artist.id}`}>
                <a href={artistUrl(artist)}>{artist.name}</a>
              </li>
            ))}
          </ul>
        ))}
      </div>
    );
  }

  function renderNavigation() {
    return (
      <div className="artists-nav">
        <a href={`${base_url}/featured`}>{featured_label}</a>
        <ul>
          {firstLetters.map((letter) => (
            <li key={letter}>
              <a
                href={`${base_url}#${letter}`}
                className={navClass(letter)}
                onClick={handleFilterClick(letter)}>
                {letter}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  return (
    <div className="artists-list artists-list-3">
      {renderNavigation()}
      {renderArtists()}
    </div>
  );
}
