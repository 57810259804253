type Props = {
  lot: CustomerLot.Lot;
  strings: CustomerLot.Localizations;
  url: string;
};

export default function Results(props: Props) {
  const { lot, strings, url } = props;

  if (!lot.ended && lot.winner) {
    return <span className="bid-status winner">{strings.status.winner}</span>;
  } else if (lot.ended && lot.winner) {
    return <span className="bid-status won">{strings.status.won}</span>;
  } else if (!lot.ended) {
    return (
      <span className="bid-status outbid">
        {strings.status.outbid}
        <a href={url}>{strings.bid_again}</a>
      </span>
    );
  } else {
    return <span className="bid-status">{strings.status.lost}</span>;
  }
}
