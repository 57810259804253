type Props = {
  label: string;
  name: string;
  onChange: (evt: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  type?: string;
  placeholder?: string;
  prefix?: string;
};

export default function TextField(props: Props) {
  let id = props.name;
  let name = props.name;

  if (props.prefix) {
    id = `${props.prefix}_${id}`;
    name = `${props.prefix}[${name}]`;
  }

  return (
    <div className="field">
      <label htmlFor={id}>{props.label}</label>
      <input
        type={props.type || "text"}
        id={id}
        name={name}
        onChange={props.onChange}
        placeholder={props.placeholder || ""}
        value={props.value}
      />
    </div>
  );
}
